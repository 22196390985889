import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout";
import * as styles from "./blog-post.module.css";
import SEO from "../components/seo";

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    if (typeof twttr?.widgets !== "undefined") {
      twttr.widgets.load();
    }
  }
  render() {
    const post = get(this.props, "data.contentfulBlogPost");
    return (
      <Layout location={this.props.location}>
        <SEO
          title={post.title}
          description={post.description.description}
          image={post.heroImage?.fluid}
        />
        {post && (
          <>
            <div className="mb-3">
              <Helmet title={`${post.title}`} />
              {post.heroImage && (
                <Img
                  alt={post.title}
                  fluid={post.heroImage?.fluid}
                  className="m-auto"
                  style={{ maxWidth: "700px", maxHeight: "300px" }}
                />
              )}
            </div>
            <div className={styles.contentWrapper}>
              <h1>{post.title}</h1>
              <p
                style={{
                  display: "block",
                }}
              >
                {post.publishDate}
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.body?.childMarkdownRemark?.html,
                }}
                className="mb-10"
              />
              <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="twitter-share-button"
                data-size="large"
                data-via="yukamasuda"
                data-show-count="false"
              >
                Tweet
              </a>
            </div>
          </>
        )}
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      description {
        description
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
